/* Сброс стандартных отступов и стилей */
*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Общие стили для body */
html, body {
    width: 100%; /* Ширина на всю страницу */
    height: 100%; /* Высота на всю страницу */
    font-family: 'Roboto', sans-serif;
    overflow-x: hidden; /* Отключаем горизонтальную прокрутку */
    background-color: #f4f4f4; /* Цвет фона */
    min-height: 100vh;
}

/* Стили для заголовков */
h2 {
    font-size: 2rem;
}

p {
    font-size: 1.2rem;
}

/* Стили для кнопки Learn More */
button {
    padding: 0.5rem 1rem;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
}

button:hover {
    background-color: #155a8a;
}

/* Стили для футера */
footer {
    background-color: #1976d2;
    color: white;
    padding: 2rem 0;
    text-align: center;
    width: 100%; /* Ширина футера на всю страницу */
    position: relative; /* Убираем sticky */
    bottom: 0;
    box-sizing: border-box; /* Учитываем padding в ширину */
}

/* Адаптивные стили для мобильных устройств */
@media (max-width: 600px) {
    /* Уменьшаем шрифты для мобильных устройств */
    h2 {
        font-size: 1.5rem; /* Уменьшаем шрифт заголовка на мобильных */
    }

    p {
        font-size: 1rem; /* Уменьшаем шрифт текста на мобильных */
    }

    footer {
        padding: 1rem 0; /* Меньше отступов в футере */
    }

    /* Отключаем горизонтальную прокрутку */
    body {
        overflow-x: hidden;
    }
}