.course-detail {
    margin-top: 20px;
}

.course-detail-image {
    width: 100%;
    max-height: 300px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 8px;
}

.course-list {
    padding-left: 20px;
    margin-bottom: 0;
}

.course-list li {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.6);
    line-height: 1.6;
}

.enrollment-form-container {
    margin-top: 20px;
    margin-bottom: 20px;
}
