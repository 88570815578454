.course-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    max-width: 300px;
    margin: 0 auto;
    padding: 16px; /* Отступы внутри карточки */
}

.course-card .course-date,
.course-card .course-price,
.course-card .course-description {
    margin-bottom: 12px; /* Отступы между блоками */
    padding: 0 8px; /* Внутренние отступы */
}

.course-card .course-date {
    font-size: 0.9rem;
    color: #6c757d;
}

.course-card .course-price {
    font-size: 0.9rem;
    color: #6c757d;
}

.course-card .course-description {
    font-size: 1rem;
    color: #333;
    margin-bottom: 16px; /* Отступ снизу для описания */
}

.course-image-container {
    width: 100%;
    height: 180px; /* Фиксированная высота для изображения */
    margin: 0 auto;
    padding: 5px; /* Отступ вокруг изображения */
    border: 1px solid #ddd; /* Рамка вокруг изображения */
    border-radius: 8px; /* Скругление углов рамки */
    background-color: #f9f9f9; /* Фон рамки */
    position: relative;
    margin-bottom: 16px; /* Отступ снизу от изображения */
}

.course-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Масштабирование изображения с сохранением пропорций */
    border-radius: 6px; /* Лёгкое скругление изображения */
}

.course-title {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background: rgba(0, 0, 0, 0.6);
    padding: 5px 10px;
    border-radius: 4px;
    font-weight: bold;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.8);
}

.course-card .MuiButton-root {
    margin-top: auto;
    margin-bottom: 1rem;
    padding: 8px 16px; /* Добавляем отступы для кнопки */
    text-transform: none; /* Отключаем преобразование текста в верхний регистр */
}
